<template>
    <div class="container repair-container" v-if="asset">

        <!-- Problems List -->
        <label>Select all problems that apply</label>
        <ul class="list-group selection-list">
            <li class="list-group-item" v-for="problem in availableAssetProblems" :key="problem.id" :class="{'active': problemActive(problem)}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" :id="problem.code" :checked="problemActive(problem)" @change="toggleAssetProblem(problem)">
                    <label class="custom-control-label mb-0" :for="problem.code">{{problem.title}}</label>
                </label>
            </li>
        </ul>

        <div class="row mt-5" v-if="asset.asset_type !== 'Customer Mail-in'">
            <div class="col-md-6 offset-md-3">
                <button class="btn btn-danger btn-block" v-b-modal.cannot-repair-asset-modal>Can't repair asset</button>
            </div>
        </div>

        <!-- Footer -->
        <footer class="fixed-footer active">
            <div class="container">
                <router-link :to="{ name: 'bulkRepairDiagnostics.device', params:{assetId: assetId}}" class="btn btn-light"><i class="fa fa-arrow-left"></i> Prev</router-link>
                <router-link :to="{ name: 'bulkRepairDiagnostics.symptomCart', params:{assetId: assetId}}" v-if="addedAssetProblems.length > 0" class="btn btn-success icon-right ml-auto">Next <i class="fa fa-arrow-right"></i></router-link>
            </div>
        </footer>

        <!-- Cannot repair modal -->
        <cannot-repair-asset-modal></cannot-repair-asset-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        props: ['assetId'],

        data() {
            return {
                selectedProblems: null,
            }
        },

        computed: {
            ...mapGetters([
                'addedAssetProblems',
                'asset',
                'assetDevice',
                'availableAssetProblems'
            ])
        },

        mounted() {
            this.loadAsset({
                assetId: this.assetId
            }).then(() => {
                this.loadAssetDevice({
                    assetId: this.assetId,
                    ownerId: this.asset.owner_id,
                }).then(() => {
                    this.loadAvailableAssetProblems({
                        makeId: this.assetDevice.make_id,
                        typeId: this.assetDevice.type_id,
                        modelId: this.assetDevice.model_id,
                    });
                    this.loadAddedAssetProblems({
                        assetId: this.assetId,
                        ownerId: this.asset.owner_id,
                    });
                });
            });
        },

        methods: {
            ...mapActions([
                'addAssetProblem',
                'clearAvailableAssetProblems',
                'loadAddedAssetProblems',
                'loadAsset',
                'loadAssetDevice',
                'loadAvailableAssetProblems',
                'removeAssetProblem'
            ]),

            /** Highlight any active problems */
            problemActive(problem) {
                let arr = this.addedAssetProblems;
                return arr.some(el => el.code === problem.code);
            },

            /** Add or remove problem from asset */
            toggleAssetProblem(problem) {
                let arr = this.addedAssetProblems;

                if (arr.some(el => el.code === problem.code)) {
                    this.removeAssetProblem({
                        assetId: this.assetId,
                        ownerId: this.asset.owner_id,
                        problem: problem
                    });
                } else {
                    this.addAssetProblem({
                        assetId: this.assetId,
                        ownerId: this.asset.owner_id,
                        problem: problem
                    }).then((problem) => {
                        this.selectedProblems = [];
                        this.selectedProblems.push({
                            problem: problem,
                            selected: true
                        });
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>